<template>
    <div class="flex flex-1 items-center justify-between gap-6">
        <div class="flex gap-3 items-center">
            <input @change="$emit('licensePackEvent', item)" v-model="item.checked" type="checkbox" :id="item.name" class="checkbox cursor-pointer checkbox-primary checkbox-sm flex" />
            <div class="flex flex-col gap-1 text-gray-500 tracking-wide font-semibold">
                <div class="flex gap-4 items-center">
                    <label :for="item.name" class="cursor-pointer text-gray-700 font-semibold truncate">{{ item.name }}</label>
                    <div v-if="item.read_only" class="bg-yellow-500 font-semibold text-sm px-1 rounded-lg w-auto text-white">
                        RO
                    </div>
                </div>
                <span class="text-xs">{{ item.type }}</span>
            </div>
        </div>
        <div>
            <Toggle :disabled="!item.checked" :title="item.checked && 'Read Only'" :checked="item.read_only" @change="$emit('toggleEvent', item)" />
        </div>
    </div>
</template>

<script>
import Toggle from "@shared/components/toggle";

export default {
    name: "feature-list",
    components: {
        Toggle,
    },
    props: {
        item:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
        };
    },
    computed: {},
    async mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
