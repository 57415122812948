import { render, staticRenderFns } from "./feature-list-item.vue?vue&type=template&id=6632e267&scoped=true"
import script from "./feature-list-item.vue?vue&type=script&lang=js"
export * from "./feature-list-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6632e267",
  null
  
)

export default component.exports